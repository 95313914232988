.ant-menu.ant-menu-sub {
  background: transparent !important;
}
.ant-menu-submenu-title {
  padding-left: 0.875rem !important;
}
.ant-menu.ant-menu-sub {
  background: transparent !important;
}
.ant-menu-submenu-title {
  padding-left: 0.875rem !important;
}
.ant-steps.ant-steps-with-progress.ant-steps-label-vertical
  .ant-steps-item
  .ant-steps-item-tail {
  top: 5px;
}
.ant-menu-submenu-title {
}
.ant-menu-item a {
  transition: none;
}
.ant-btn-primary {
  background: theme("colors.accent");
}
.ant-switch {
  background: #f2f4f7;
}
.dark .ant-switch {
  background: #3e3e3f;
}
.dark .ant-switch.ant-switch-checked {
  background: theme("colors.accent");
}
@media screen and (max-width: 1439px) {
  .ant-menu-submenu-title {
    height: 32px !important;
  }
}
.ant-steps-item-title {
  font-size: 12px !important;
}
.ant-skeleton.ant-skeleton-element {
  display: flex !important;
  align-items: end;
}
.ant-color-picker-trigger .ant-color-picker-clear::after {
  display: none;
}
.ant-color-picker-trigger.ant-color-picker-trigger-active {
  border-inline-end-width: initial;
  box-shadow: none;
  outline: 0;
}
.ant-color-picker-trigger .ant-color-picker-color-block {
  border-radius: 50%;
}
.ant-color-picker-trigger {
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid #EBEBEB;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  background: #ffffff;
  padding: 0;
}
.ant-color-picker-trigger .ant-color-picker-clear {
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, #FB2E2E 0deg, rgba(251, 120, 46, 0.80) 51.42857372760773deg, #FBE62E 102.85714745521545deg, #88FB2E 154.28571581840515deg, #2EFBEF 205.7142949104309deg, #2E43FB 257.1428632736206deg, #C62EFB 308.5714316368103deg, #FB2E6C 360deg) !important;
}


.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}


.rdw-option-wrapper {
  border: none !important;
  padding: 1px 7px !important;
  min-width: 25px !important;
  height: 30px !important;
  border-radius: 5px !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  background: transparent !important;
  text-transform: capitalize !important;
}
.rdw-option-active {
  box-shadow: none !important;
  background: #d3dce6 !important;
}
.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
  background: #eff2f7;
  padding: 4px;
  border-radius: 7px;
  gap: 4px;
}

.rdw-editor-toolbar {
  padding: 0 !important;
  border-radius: 2px !important;
  border: none !important;
  display: flex !important;
  justify-content: flex-start !important;
  gap: 3px !important;
  background: none !important;
  flex-wrap: wrap !important;
  font-size: 15px !important;
  margin-bottom: 5px !important;
  -webkit-user-select: none !important;
  user-select: none !important;
}


.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
  background: #eff2f7;
  padding: 4px;
  border-radius: 7px;
  gap: 4px;
}

.rdw-dropdown-wrapper {
  height: 38px !important;
  cursor: pointer !important;
  border: none !important;
  border-radius: 7px !important;
  margin: 0;
  text-transform: capitalize !important;
  background: white !important;
  background: #eff2f7 !important;
  padding: 0px !important;
}
.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
  background: #eff2f7;
  padding: 4px;
  border-radius: 7px;
  gap: 4px;
}
.rdw-image-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
  background: #eff2f7;
  padding: 4px;
  border-radius: 7px;
  gap: 4px;
}
.rdw-option-wrapper:hover {
  box-shadow: inset 1px 1px 4px 0px #BFBDBD !important;
}

.page-controls {
  
  /* transition: opacity ease-in-out .2s; */
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);

}
.page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: .8em;
  border-radius: 4px;
}
.page-controls button:enabled:hover, .page-controls button:enabled:focus {
  background-color: #e6e6e6;
}

/* CKEditor Theme Overrides  */

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content, .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content.ck-rounded-corners {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ck-rounded-corners .ck.ck-toolbar, .ck.ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.ck.ck-button.ck-on, a.ck.ck-button.ck-on {
  color: var(--primary-color) !important;
  background: rgba(var(--primary), 0.1) !important;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid var(--primary-color) !important;
}
.dark .ck.ck-toolbar {
  background: #3f3e3e00;
}
.dark .ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all {
  color: #fff;
}
.ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:active, a.ck.ck-button:focus {
  /* border: var(--ck-focus-ring); */
  box-shadow: none !important;
  outline: none;
}